














































































































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import AlertRequestModel from "@/alerts-system/models/AlertRequestModel";
import {
  AlertBorder,
  AlertDisplayTime,
  AlertFeature,
  AlertType,
} from "@/alerts-system/models/AlertResponseModel";
import { Application, MenuItems } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component({ components: { DatesPicker } })
export default class AlertForm extends Vue {
  @Prop() value!: AlertRequestModel;
  @Prop({ default: "" }) title!: string;

  @Ref("form") readonly form!: VueForm;

  readonly today = DateUtil.today();
  readonly typeItems = Object.values(AlertType).map((type) => type);
  readonly borderItems = Object.values(AlertBorder).map((border) => border);
  readonly featureItems = Object.entries(AlertFeature).map(([key, value]) => ({
    text: this.$lang(`alertsSystem.${key.toLowerCase()}`),
    value,
    disabled: value !== AlertFeature.REPORTS,
  }));
  readonly alertModeItems = [
    { text: this.$lang("alertsSystem.tableHeader.wholeSystem"), value: true },
    {
      text: this.$lang("alertsSystem.specificApplications"),
      value: false,
    },
  ];
  readonly displayTimeItems = Object.entries(AlertDisplayTime).map(
    ([key, value]) => ({
      text: this.$lang(`alertsSystem.${key.toLowerCase()}`),
      value,
    })
  );
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ];
  readonly MenuItems = MenuItems;
  readonly AlertDisplayTime = AlertDisplayTime;
  localValue: AlertRequestModel = AlertRequestModel.ofSource(this.value);
  isValid = false;
  alert = true;
  isForAllSystem = !this.localValue?.applications?.length;
  validEditor = true;

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.alertsSystemStore.isSavingInProgress;
  }

  get applications(): Array<Application> {
    return this.$store.state.application.apps.map(
      ({ name, id }: Application) => ({
        text: name || id,
        value: id,
      })
    );
  }

  get isShowDatePicker(): boolean {
    return [
      AlertDisplayTime.DATETIME_LIMITED,
      AlertDisplayTime.FEATURE_TIME_LIMITED,
    ].includes(this.localValue.displayTime);
  }

  get subFeatureItems(): Array<Record<string, string>> {
    const reports = Array.from(
      new Set(
        Object.values(
          this.$store.state.userStore.currentUser.reportAccessByApp
        ).flat() as Array<ReportType>
      )
    );

    if (this.localValue.feature !== AlertFeature.REPORTS || !reports) {
      return [];
    }

    return reports
      .map((value: ReportType) => ({
        value,
        text: ReportUtil.getReportNameByReportType(value),
      }))
      .sort(({ text: textA }, { text: textB }) => {
        if (textA.toLowerCase() === textB.toLowerCase()) {
          return 0;
        }

        return textA.toLowerCase() > textB.toLowerCase() ? 1 : -1;
      });
  }

  get likesAllSubFeatures(): boolean {
    return this.localValue.subFeatures.length === this.subFeatureItems.length;
  }

  get likesSomeSubFeatures(): boolean {
    return this.localValue.subFeatures.length > 0 && !this.likesAllSubFeatures;
  }

  get selectAllIcon(): string {
    if (this.likesAllSubFeatures) return "mdi-close-box";
    if (this.likesSomeSubFeatures) return "mdi-minus-box";

    return "mdi-checkbox-blank-outline";
  }

  @Watch("localValue", { deep: true })
  private watchLocalValue(value: AlertRequestModel) {
    this.$emit("input", value);
  }

  @Watch("localValue.displayTime")
  private watchLocalValueDisaplyTime(value: AlertDisplayTime) {
    if (
      value === AlertDisplayTime.DATETIME_LIMITED ||
      value === AlertDisplayTime.FEATURE_TIME_LIMITED
    ) {
      this.localValue.displayDates = [DateUtil.today(), DateUtil.today()];
    } else {
      this.localValue.displayDates = [];
    }
  }

  @Watch("localValue.text")
  private watchLocalValueText(value: string) {
    if (!this.validEditor && value) {
      this.validEditor = true;
    } else if (this.validEditor && !value) {
      this.validEditor = false;
    }
  }

  @Watch("isForAllSystem")
  watchIsForAllSystem() {
    this.localValue.applications = [];
  }

  handleSave() {
    if (!this.form.validate()) {
      return;
    }
    this.$emit("save", this.localValue);
  }

  editorBlur() {
    if (!this.localValue.text) {
      this.validEditor = false;
    } else {
      this.validEditor = true;
    }
  }

  handleSelectingAllSubFeatures() {
    if (this.likesAllSubFeatures) {
      this.localValue.subFeatures = [];
    } else {
      this.localValue.subFeatures = this.subFeatureItems.map(
        (subFeature) => subFeature.value
      );
    }
  }
}
