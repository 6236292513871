var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","to":{
        name: _vm.MenuItems.USER_ALERTS,
      }}},[_vm._v(" "+_vm._s(_vm.$lang("btn.toTheList"))+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","loading":_vm.isSavingInProgress,"disabled":!_vm.isValid || !_vm.localValue.text},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.$lang("btn.save"))+" ")])],1),_c('v-card-text',[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-select',{attrs:{"items":_vm.alertModeItems,"label":_vm.$lang('alertsSystem.alertMode'),"outlined":"","dense":""},model:{value:(_vm.isForAllSystem),callback:function ($$v) {_vm.isForAllSystem=$$v},expression:"isForAllSystem"}}),(!_vm.isForAllSystem)?_c('v-autocomplete',{attrs:{"items":_vm.applications,"label":_vm.$lang('alertsSystem.applications'),"rules":_vm.requiredRule,"outlined":"","multiple":"","dense":""},model:{value:(_vm.localValue.applications),callback:function ($$v) {_vm.$set(_vm.localValue, "applications", $$v)},expression:"localValue.applications"}}):_vm._e(),_c('v-select',{attrs:{"items":_vm.featureItems,"label":_vm.$lang('alertsSystem.tableHeader.feature'),"rules":_vm.requiredRule,"outlined":"","dense":""},model:{value:(_vm.localValue.feature),callback:function ($$v) {_vm.$set(_vm.localValue, "feature", $$v)},expression:"localValue.feature"}}),_c('v-autocomplete',{attrs:{"items":_vm.subFeatureItems,"label":_vm.$lang('alertsSystem.tableHeader.subFeatures'),"small-chips":"","deletable-chips":"","multiple":"","outlined":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.handleSelectingAllSubFeatures}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.localValue.subFeatures.length > 0
                        ? 'indigo darken-4'
                        : ''}},[_vm._v(" "+_vm._s(_vm.selectAllIcon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang("alertsSystem.selectAll"))+" ")])],1)],1),_c('v-divider')]},proxy:true}]),model:{value:(_vm.localValue.subFeatures),callback:function ($$v) {_vm.$set(_vm.localValue, "subFeatures", $$v)},expression:"localValue.subFeatures"}}),_c('v-select',{attrs:{"items":_vm.displayTimeItems,"label":_vm.$lang('alertsSystem.tableHeader.displayTime'),"outlined":"","dense":""},model:{value:(_vm.localValue.displayTime),callback:function ($$v) {_vm.$set(_vm.localValue, "displayTime", $$v)},expression:"localValue.displayTime"}}),(_vm.isShowDatePicker)?_c('dates-picker',{staticClass:"mb-6",attrs:{"min":_vm.localValue.displayTime === _vm.AlertDisplayTime.FEATURE_TIME_LIMITED
                ? ''
                : _vm.today},model:{value:(_vm.localValue.displayDates),callback:function ($$v) {_vm.$set(_vm.localValue, "displayDates", $$v)},expression:"localValue.displayDates"}}):_vm._e(),(
              _vm.localValue.displayTime === _vm.AlertDisplayTime.FEATURE_TIME_LIMITED
            )?[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":_vm.$lang('alertsSystem.displayableOnChart')},model:{value:(_vm.localValue.isDisplayableOnChart),callback:function ($$v) {_vm.$set(_vm.localValue, "isDisplayableOnChart", $$v)},expression:"localValue.isDisplayableOnChart"}}),(_vm.localValue.isDisplayableOnChart)?_c('v-text-field',{attrs:{"rules":_vm.requiredRule,"label":_vm.$lang('alertsSystem.title'),"outlined":"","dense":""},model:{value:(_vm.localValue.title),callback:function ($$v) {_vm.$set(_vm.localValue, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localValue.title"}}):_vm._e()]:_vm._e(),_c('v-select',{attrs:{"items":_vm.typeItems,"label":_vm.$lang('alertsSystem.tableHeader.type'),"outlined":"","dense":""},model:{value:(_vm.localValue.details.type),callback:function ($$v) {_vm.$set(_vm.localValue.details, "type", $$v)},expression:"localValue.details.type"}}),_c('v-select',{attrs:{"items":_vm.borderItems,"label":_vm.$lang('alertsSystem.border'),"outlined":"","dense":""},model:{value:(_vm.localValue.details.border),callback:function ($$v) {_vm.$set(_vm.localValue.details, "border", $$v)},expression:"localValue.details.border"}}),_c('vue-editor',{class:[
              { 'has-error': !_vm.validEditor },
              _vm.dark ? 'theme--dark' : 'theme--light' ],attrs:{"editor-toolbar":_vm.customToolbar,"placeholder":_vm.$lang('alertsSystem.exampleText')},on:{"blur":_vm.editorBlur},model:{value:(_vm.localValue.text),callback:function ($$v) {_vm.$set(_vm.localValue, "text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localValue.text"}}),(!_vm.validEditor)?_c('span',{staticClass:"text-caption red--text ml-3"},[_vm._v(" "+_vm._s(_vm.$lang("validation.required"))+" ")]):_vm._e()],2)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"border":_vm.localValue.details.border !== 'none'
              ? _vm.localValue.details.border
              : undefined,"type":_vm.localValue.details.type,"dismissible":"","text":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_c('span',{domProps:{"innerHTML":_vm._s(
              !_vm.localValue.text
                ? _vm.$lang('alertsSystem.exampleText')
                : _vm.localValue.text
            )}})]),_c('div',{staticClass:"text-center"},[(!_vm.alert)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.alert = true}}},[_vm._v(" "+_vm._s(_vm.$lang("btn.reset"))+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }