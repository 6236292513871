









import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import AlertForm from "@/alerts-system/components/AlertForm.vue";
import AlertResponseModel from "@/alerts-system/models/AlertResponseModel";
import AlertRequestModel from "@/alerts-system/models/AlertRequestModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { MenuItems } from "@/shared/models";

@Component({
  components: {
    AlertForm,
  },
})
export default class AlertView extends mixins(UnsavedChangesMixin) {
  localValue = new AlertRequestModel();
  isLoading = true;
  isWatchedAlert = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localValue);
  }

  get alertId(): string {
    return this.$route.params.alertId;
  }

  get alert(): AlertResponseModel {
    return this.$store.state.alertsSystemStore.alert;
  }

  @Watch("alert", { deep: true })
  private watchAlert(alert: AlertResponseModel) {
    this.localValue = AlertRequestModel.ofSource(alert);

    if (!this.isWatchedAlert) {
      this.isWatchedAlert = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("alertsSystem.titaleForEditing")
    );
  }

  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("loadAlert", this.alertId);
    this.isLoading = false;
    this.handleWatchingUnsavedChanges();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async handleSave(payload: AlertRequestModel) {
    await this.$store.dispatch("updateAlert", payload);
    this.isSavedForm = true;
    this.$router.push({
      name: MenuItems.USER_ALERTS,
    });
  }
}
